import React, { useCallback, useState } from "react"
import clsx from "clsx"
import { Box, makeStyles } from "@material-ui/core"
import HiddenCss from "@material-ui/core/Hidden/HiddenCss"

import { DialogsProvider } from "../Dialogs"

import { CssBaseLine } from "./CssBaseLine"
import { Header, HeaderProps } from "./Header"
import { Footer } from "./Footer"
import { DrawerMenu } from "./DrawerMenu"
import { FloatingMenuButton } from "./FloatingMenuButton"
import { Seo, SeoProps } from "../seo"



export interface LayoutProps {
	seo: SeoProps,
	headerProps: Omit<HeaderProps, "openMenu">,
	children: React.ReactNode,
}



export const Layout = (props: LayoutProps) => {
	const { seo, headerProps, children } = props;

	const classes = useStyles();

	const [ menuOpen, setMenuOpen ] = useState(false);
	const openMenu = useCallback(() => setMenuOpen(true), [setMenuOpen]);
	const closeMenu = useCallback(() => setMenuOpen(false), [setMenuOpen]);

	return (
		<>
			<Seo {...seo} />
			<CssBaseLine />
			<DialogsProvider>
				<div className={clsx(classes.page, menuOpen && classes.page__menuOpen)}>
					<Header {...headerProps} />
					<Box paddingY={4}>
						{children}
					</Box>
					<Footer />
				</div>
				<HiddenCss mdUp>
					<FloatingMenuButton onClick={openMenu}/>
					<DrawerMenu
						open={menuOpen}
						onOpen={openMenu}
						onClose={closeMenu}
					/>
				</HiddenCss>
			</DialogsProvider>
		</>
	);
}


const useStyles = makeStyles(theme => ({
	page: {
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.enteringScreen,
			easing: theme.transitions.easing.easeOut,
		}),
	},
	page__menuOpen: {
		transform: 'translateY(-100px)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.easeOut,
		}),
	}

}));
