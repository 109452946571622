import React from "react"
import { Typography } from "@material-ui/core";



export const GdprContent = () => {
	return (
		<>
			<p>
				Prehlasujeme, že vaše osobné údaje spracovávame v súlade s právnymi predpismi, konkrétne v súlade s NARIADENÍM EUROPSKÉHO PARLAMENTU A RADY (EU) 2016/679, o ochrane fyzických osôb v súvislosti so spracovaním osobných údajov a o voľnom pohybe týchto údajov a o zrušení smernice 95/46/ES, všeobecné nariadenie o ochrane osobných údajov (ďalej len ako „GDPR“).
			</p>
			{/*<p>*/}
			{/*	Prevádzkovateľom Hotela Studničky*** a webovej stránky studnicky.sk je:*/}
			{/*</p>*/}

			{/*Ján Alexa*/}
			{/*IČO:*/}
			{/*IČ DPH:*/}

			<Typography variant="h4">Aké údaje spracovávame</Typography>
			<p>Na našej webstránke máte možnosť kontaktovať nás prostredníctvom formulárov - kontaktného a rezervačného formuláru. Súčasťou každého formulára je aj zaklikávacia položka, ktorou nám udeľujete súhlas na spracovanie vami zadaných údajov na daný konkrétny účel. Informácie, ktoré vypĺňate v našich formulároch zahŕňajú povinné a nepovinné údaje. Bez uvedenia povinných údajov nie je možné konkrétny formulár odoslať a kontaktovať nás. Vyplnením formulára a zaškrnutím súhlasu so spracovaním osobných údajov nám dobrovoľne poskytnete osobné údaje v rozsahu uvedenom vo vyplnenom formulári. Konkrétne osobné údaje, ktoré od vás vo formulároch žiadame:</p>
			<ul>
				<li>Meno a priezvisko</li>
				<li>Telefónne číslo</li>
				<li>Emailová adresa</li>
			</ul>
			<p>
				Pri používaní nášho webu studnicky.sk dochádza k zhromažďovaniu údajov pomocou súborov cookie tretích strán. Cookies tretích strán používame ku zhromažďovaniu štatistických údajov v súhrnnej podobe pomocou analytických nástrojov, ako je Google, Google Analytics, Facebook.
				Tieto cookies zhromažďujú informácie o tom, ako naše webové stránky používate, napr. ktoré stránky navštevujete, a či ste narazili na nejaké chyby, napríklad vo formulároch a informácie o prehliadači, geolokácii, jazykovej lokácii, o čase strávenom v určitej časti nášho webu a pod. Tieto cookies nezhromažďujú žiadne informácie, podľa ktorých by bolo možné zistiť vašu totožnosť – všetky zhromaždené údaje sú anonymné. Používame tiež inzertné funkcie služby Google Analytics a Facebook Pixel. Neovládame cookies z webových stránok tretích strán. Tretie strany zodpovedajú za to, ako funguje ich cookies a ako sú spracovávané osobné údaje. Zakázaním týchto cookies nemôžeme zaručiť plnú funkčnosť našej stránky.
			</p>

			<Typography variant="h4">Ako skontrolovať nastavenie cookies</Typography>
			<p>
				Súbory cookie môžete kontrolovať alebo zmazať podľa uváženia. Návod, ako zachádzať so súbormi cookies a ako ich odstrániť, nájdete v „Nápovede“ svojho prehliadača.
			</p>

			<Typography variant="h4">Za akým účelom spracovávame vaše údaje</Typography>

			<p>
				Osobné údaje uvedené v kontaktnom a rezervačnom formulári sú spracovávané za účelom spätného kontaktovania, odpovede na vašu správu a dokončenia rezervacie pobytu. Osobné údaje uvedené vo formulári na odber noviniek (newslettra) sú spracovávané za účelom zasielania noviniek a reklamných správ.
			</p>
			<p>
				Pomocou súborov cookie tretích strán:
			</p>
			<ul>
				<li>Získavame údaje o tom, ako sa naše webové stránky používajú</li>
				<li>Získavame údaje o prehliadači, geolokácii, jazykovej lokácii, o čase strávenom v určitej časti nášho webu a pod.</li>
				<li>Môžeme zachytiť prípadné chyby a ich odstránením stránky skvalitňovať</li>

			</ul>
			<p>Ako dlho uchovávame dáta?</p>
			<p>Údaje z prehliadania uchované podľa nastavenia cookies nie sú uchovávané v našich systémoch.</p>

			<Typography variant="h4">Vaše práva a možnosti</Typography>
			<ul>
				<li>Právo odvolať súhlas – V prípade ak spracúvame vaše údaje na základe vášho súhlasu je možné tento súhlas kedykoľvek odvolať alebo namietať zaslaním podnetu, stačí ak nás budete kontaktovať.</li>
				<li>Právo na prístup k svojim údajom – Môžete si vyžiadať informácie o tom, ako spracovávame vaše osobné údaje, vrátane informácií o tom prečo spracovávame vaše osobné údaje, aké kategórie osobných údajov spracovávame, s kým vaše osobné údaje zdieľame, ako dlho tieto údaje uchovávame, či spracovanie zahŕňa automatizované rozhodovanie, či tieto údaje boli prevedené do tretej krajiny.</li>
				<li>Právo na opravu -máte právo nás upozorniť, ak je niektorý z vašich osobných údajov nesprávny, napr. ak ste si zmenili meno alebo ak ste sa presťahovali a my máme povinnosť nesprávne údaje opraviť.</li>
				<li>Právo byť zabudnutý – ak spracovávame vaše osobné údaje nezákonným spôsobom, napríklad ak spracovávame vaše osobné údaje dlhšie, než je potrebné alebo bezdôvodne, môžete nás požiadať o vymazanie týchto údajov.</li>
				<li>Právo na obmedzenie – od momentu, kedy ste požiadali o opravu vašich osobných údajov alebo ak ste namietali proti spracovaniu a do momentu, kým nebudeme môcť problém vyšetriť alebo potvrdiť presnosť vašich osobných údajov (alebo ich podľa vašich pokynov zmeniť), máte nárok na obmedzené spracovanie. To znamená, že my (s výnimkou uchovávania osobných údajov) môžeme spracovávať vaše osobné údaje iba v súlade s vaším súhlasom, ak je to potrebné v súvislosti s právnymi nárokmi, na ochranu práv niekoho iného, alebo ak existuje významný verejný záujem na spracovaní. Môžete tiež požiadať, aby sme obmedzili spracovanie vašich osobných údajov, ak je spracovanie nezákonné, ale nechcete, aby sme osobné údaje vymazali.</li>
				<li>Právo namietať – ak sa domnievate, že nemáme právo na spracovanie vašich osobných údajov, môžete proti nášmu spracovaniu namietať. V takýchto prípadoch môžeme pokračovať v spracovávaní iba vtedy, ak vieme preukázať presvedčivé oprávnené dôvody, ktoré prevážia vaše záujmy, práva a slobody. Vaše osobné údaje však môžeme vždy spracovať, ak je to potrebné na určenie, uplatnenie alebo obhajobu právnych nárokov.</li>
				<li>Právo na prenos údajov – Môžete požiadať o to, aby vám vaše osobné údaje, ktoré ste nám poskytli na spracovanie na základe súhlasu alebo na splnenie zmluvy, boli poskytnuté v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte. Máte tiež právo požiadať o prenos týchto informácií na iného správcu údajov.</li>
			</ul>

			<Typography variant="h4">Ako môžete uplatniť svoje práva?</Typography>
			<p>
				Ak chcete získať ďalšie informácie o spracúvaní osobných údajov alebo ak máte nejaké otázky, môžete nás kontaktovať.
				Máte právo podať sťažnosť na Dozorný orgán ktorým je Úrad na ochranu osobných údajov (ÚOOÚ). Ďalšie informácie o ÚOOÚ a ich postupe podávania sťažností nájdete tu: www.dataprotection.gov.sk.
			</p>
		</>
	)
};
