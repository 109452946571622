import React, { CSSProperties, useMemo } from "react";
import { makeStyles } from "@material-ui/core";

import { Image } from "../../Image";



export interface HeaderCoverProps {
	coverImage: string,
	coverAlign?: string,
	coverTranslate: number,
	collapseProgress: number | undefined,
}


export const HeaderCover = (props: HeaderCoverProps) => {
	const { coverImage, coverAlign, coverTranslate, collapseProgress } = props;
	const classes = useStyles();

	const coverStyle = useMemo(
		(): CSSProperties => ({
			transform: `translateY(${coverTranslate}px)`,
			opacity: collapseProgress === undefined ? undefined : (1 - collapseProgress * 0.5),
		}),
		[coverTranslate, collapseProgress],
	);

	return (
		<div className={classes.cover} style={coverStyle}>
			<Image
				file={coverImage}
				loading="eager"
				className={classes.background}
				objectFit="cover"
				objectPosition={coverAlign}
				alt=""
			/>
		</div>
	);
};

const offset = 0;

const useStyles = makeStyles(theme => ({

	cover: {
		position: "absolute",
		left: -offset, right: -offset,
		bottom: -offset, top: -offset,
		willChange: "transform",
	},

	background: {
		height: '100%',
	},

}));

