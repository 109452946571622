import React from "react"

import { Container } from "@material-ui/core"

import { Layout } from "./components/Layout"



export default (props: any, ...rest: any) => {
	const { children } = props;
	
	// console.log(props.pageContext.frontmatter);
	const meta = props.pageContext.frontmatter || {};
	
	const lang = meta.lang || '';
	const title = meta.title || '';
	const description = meta.description || '';
	const robots = meta.robots || '';
	
	let heading = meta.heading || title;
	if (Array.isArray(meta.heading)) {
		if (heading.length === 2) {
			heading = (<><small>{heading[0]}</small><br />{heading[1]}</>);
		} else {
			heading = heading[0];
		}
	}
	
	const cover = meta.cover || ['lg', 'okolie/20200516_181238.jpg', 100, 60];

	return (
		<Layout
			seo={{
				lang,
				title,
				description,
				robots,
			}}
			headerProps={{
				coverSize: cover[0],
				coverImage: cover[1],
				coverAlign: `${cover[2]}% ${cover[3]}%`,
				title: heading,
			}}
		>
			<Container maxWidth="lg">
				{children}
			</Container>
		</Layout>
	);
}
