import React from "react"
import { CssBaseline as MuiCssBaseline } from "@material-ui/core"



export const CssBaseLine = () => {
	return (
		<MuiCssBaseline />
	);
}
