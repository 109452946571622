import React, { useCallback, useContext, useState } from "react"
import { NoSsr } from "@material-ui/core"

import { GdprDialog } from "../Gdpr"
import { ReservationFormDialog } from "../ReservationForm"



const defaultDialogsState = {
	gdpr: false,
	reservation: false,
}


type DialogsState = typeof defaultDialogsState;
type OpenDialogFunction = (name: keyof DialogsState) => void;

const dialogsControlsContext = React.createContext<OpenDialogFunction>((name: keyof DialogsState) => 0);


export const useOpenDialogFunction = () => useContext(dialogsControlsContext);
export const useHandleDialogOpen = (dialogName: keyof DialogsState, extraCallback?: () => void) => {
	const openDialog = useOpenDialogFunction();
	return useCallback((evt: React.MouseEvent) => {
		if (evt.ctrlKey || evt.shiftKey || evt.altKey || evt.metaKey) {
			return;
		}
		evt.preventDefault();
		openDialog(dialogName);
		if (extraCallback) {
			extraCallback();
		}
	}, [openDialog, extraCallback, dialogName]);
}


export interface DialogsProviderProps {
	children: React.ReactNode,
}


export const DialogsProvider = (props: DialogsProviderProps) => {
	const { children } = props;

	const [dialogsState, setDialogsState] = useState<DialogsState>(defaultDialogsState);

	const setDialogState = useCallback((name: keyof DialogsState, state: boolean) => setDialogsState(prevDialogState => ({ ...prevDialogState, [name]: state })), [setDialogsState]);
	const openDialog = useCallback((name: keyof DialogsState) => setDialogState(name, true), [setDialogState]);
	const closeDialog = useCallback((name: keyof DialogsState) => setDialogState(name, false), [setDialogState]);
	const closeGdprDialog = useCallback(() => closeDialog("gdpr"), [closeDialog]);
	const closeReservationDialog = useCallback(() => closeDialog("reservation"), [closeDialog]);

	return (
		<dialogsControlsContext.Provider value={openDialog}>
			{children}
			<NoSsr>
				<ReservationFormDialog open={dialogsState.reservation} onClose={closeReservationDialog}/>
				<GdprDialog open={dialogsState.gdpr} onClose={closeGdprDialog} />
			</NoSsr>
		</dialogsControlsContext.Provider>
	);
}
