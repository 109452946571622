import React from "react"
import {
	Button,
	DialogActions,
	DialogContent, DialogContentText,
	DialogTitle,
} from "@material-ui/core"

import { GdprContent } from "./GdprContent"
import { Dialog } from "../Dialogs/Dialog"



export interface GdprModalProps {
	open: boolean,
	onClose: () => void,
}


export const GdprDialog = (props: GdprModalProps) => {
	const { open, onClose } = props;

	return (
		<Dialog
			maxWidth="md"
			open={open}
			onClose={onClose}
		>
			<DialogTitle>Informácie o spravovaní a ochrane vašich osobných údajov</DialogTitle>
			<DialogContent dividers>
				<DialogContentText component="div">
					<GdprContent />
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Zavrieť
				</Button>
			</DialogActions>
		</Dialog>
	);
}
