import React, { useState } from "react"
import { Checkbox, FormControl, FormControlLabel, Grid, makeStyles, TextField } from "@material-ui/core"
import { GdprAnchor } from "../Gdpr"
import { DatePicker } from "@material-ui/pickers"



export interface ReservationFormBodyProps {
	variant: "filled" | "outlined" | "standard";
}


export const ReservationFormBody = (props: ReservationFormBodyProps) => {
	const margin = 'none';
	const { variant } = props;
	const spacing = 2;

	const classes = useStyles();
	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);

	return (
		<>
			<Grid container spacing={spacing} alignItems={"flex-start"}>
				<Grid item sm={6}>
					<Grid container spacing={spacing}>
						<Grid item xs={6}>
							<DatePicker
								label="Príchod"
								inputVariant={variant as any}
								fullWidth
								margin={margin}
								autoOk
								disablePast
								disableToolbar
								format="d. M. yyyy"
								maxDate={dateTo || undefined}
								name="date_from"
								value={dateFrom}
								onChange={setDateFrom as any}
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<DatePicker
								label="Odchod"
								inputVariant={variant as any}
								margin={margin}
								fullWidth
								autoOk
								disablePast
								disableToolbar
								format="d. M. yyyy"
								minDate={dateFrom || undefined}
								name="date_to"
								value={dateTo}
								onChange={setDateTo as any}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Počet dospelných"
								type="number"
								name="adults_count"
								required
								variant={variant as any}
								margin={margin}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Počet detí"
								type="number"
								name="children_count"
								required
								variant={variant as any}
								margin={margin}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item sm={6}>
					<Grid container spacing={spacing} alignItems={"flex-start"}>
						<Grid item xs={12}>
							<TextField
								label="Meno a priezvisko"
								name="name"
								required
								variant={variant as any}
								margin={margin}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="E-mail"
								name="email"
								type="email"
								required
								variant={variant as any}
								margin={margin}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} className={classes.honeypot}>
							<TextField
								label="Web"
								name="web"
								type="text"
								variant={variant as any}
								margin={margin}
								fullWidth
								autoComplete="off"
								tabIndex={-1}
							/>
						</Grid>
						<Grid item xs={12} className={classes.honeypot}>
							<TextField
								label="Fax"
								name="fax"
								type="tel"
								variant={variant as any}
								margin={margin}
								fullWidth
								autoComplete="off"
								tabIndex={-1}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Telefón"
								name="phone"
								type="tel"
								required
								variant={variant as any}
								margin={margin}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Odkaz alebo iné želania k ubytovaniu"
						name="message"
						multiline
						rows={5}
						rowsMax={10}
						fullWidth
						variant={variant as any}
						margin={margin}
					/>
				</Grid>
			</Grid>

			<FormControl margin={"normal"} error={true}>
				<FormControlLabel
					control={(
						<Checkbox name="gdpr_agreement" value="1" color="primary" required />
					)}
					label={(
						<>
							{'Súhlasím so spracovaním osobných údajov na kontaktné účely '}
							(<GdprAnchor>viac informácií</GdprAnchor>).
						</>
					)}
					labelPlacement="end"
				/>
			</FormControl>
			<FormControl margin={"normal"} error={true} className={classes.honeypot}>
				<FormControlLabel
					control={(
						<Checkbox name="user_agreement" value="1" color="primary" tabIndex={-1} inputProps={{autoComplete: "off"}} />
					)}
					label={'Som Robot'}
					labelPlacement="end"
					tabIndex={-1}
				/>
			</FormControl>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	honeypot: {
		display: 'none',
	}
}));
