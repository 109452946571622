import React from "react"
import {Box, Container, Grid, makeStyles, Typography} from "@material-ui/core"
import { IoLogoFacebook, TiSocialInstagram } from "react-icons/all"

import { Anchor, IconButtonLink } from "../Link"
import { NewsletterSignUpForm } from "../NewsletterSignUpForm"




export interface FooterProps {

}


export const Footer = (props: FooterProps) => {
	const classes = useStyles();
	return (
		<footer className={classes.footer}>
			<Container maxWidth="lg">
				<Grid container spacing={2}>
					<Grid item sm={12} md={4}>
						<Box mt={4}>
							<Typography variant="body2" color="textSecondary" component="div">
								<ul className={classes.ul}>
									<li><Anchor variant="inherit" color="inherit" to="/svadby-a-rodinne-oslavy/">Svadby a rodinné oslavy</Anchor></li>
									<li><Anchor variant="inherit" color="inherit" to="/lyziarske-zajazdy/">Lyžiarske zájazdy</Anchor></li>
									<li><Anchor variant="inherit" color="inherit" to="/firmy/">Firmy</Anchor></li>
								</ul>
								<ul className={classes.ul}>
									<li><Anchor variant="inherit" color="inherit" to="/nas-pribeh/">Náš príbeh</Anchor></li>
								</ul>
							</Typography>
						</Box>
					</Grid>
					<Grid item sm={6} md={4}>
						<Box mb={1}>
							<Typography variant="h6" color="textSecondary">Newsletter</Typography>
						</Box>
						<NewsletterSignUpForm />
						<div>
							<IconButtonLink to="https://www.facebook.com/hotelstudnicky/"><IoLogoFacebook /></IconButtonLink>
							<IconButtonLink to="https://www.instagram.com/hotel_studnicky_vernar/"><TiSocialInstagram /></IconButtonLink>
						</div>
						{/*<div>*/}
						{/*	Hodnotenia našich hostí*/}
						{/*	- booking*/}
						{/*	- tripadvisor*/}
						{/*</div>*/}
					</Grid>
					<Grid item sm={1} md={1} />
					<Grid item sm={5} md={3}>
						<Box mb={1}>
						<Typography variant="h6" color="textSecondary">Hotel Studničky</Typography>
						</Box>
						<Typography variant="body2" color="textSecondary">942/2 Vernár<br/>Slovensko</Typography>
						<Box my={1}>
							<Typography variant="body2" color="textSecondary">
								<Anchor variant="inherit" color="inherit" to="mailto:info@studnicky.sk">info@studnicky.sk</Anchor><br />
								<Anchor variant="inherit" color="inherit" to="tel:+421 915 656 037">+421 915 656 037</Anchor>
							</Typography>
						</Box>
						<Typography variant="caption" color="textSecondary">© Hotel Studničky</Typography>
					</Grid>
				</Grid>

			</Container>
		</footer>
	);
}


const useStyles = makeStyles(theme => ({
	ul: {
		listStyle: 'none',
		margin: 0,
		padding: 0,
		paddingTop: theme.spacing(1),
		'& > li': {
			margin: theme.spacing(1, 0),
		}
	},
	footer: {
		borderTop: 'solid 1px #eee',
		padding: theme.spacing(4, 0),
	}
}));
