import React, { useMemo } from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";



export interface HeaderTitleProps {
	title: React.ReactNode,
	collapseProgress: number | undefined,
}


export const HeaderTitle = (props: HeaderTitleProps) => {
	const { title, collapseProgress } = props;
	const classes = useStyles();

	const titleStyle = useMemo(() => {
		if (collapseProgress === undefined) {
			return undefined;
		}
		const inverted = 1 - collapseProgress;
		return {
			opacity: inverted * inverted
		}
	}, [collapseProgress]);

	return (
		<div className={classes.root} style={titleStyle}>
			<Container maxWidth="lg">
				<Typography variant="h1" className={classes.typography}>
					{title}
				</Typography>
			</Container>
		</div>
	);
};


const useStyles = makeStyles(theme => ({

	root: {
		position: "absolute",
		left: 0, right: 0, bottom: 0,
		paddingTop: "5vh",
		paddingBottom: "5vh",
		backgroundImage: 'linear-gradient(rgba(0,0,0, 0.0), rgba(0,0,0, 0.4))'
	},

	typography: {
		color: '#fff',
		textShadow: "0 1px 2px rgba(0,0,0, 1), 0 1px 5px rgba(0,0,0, 0.5)",
	}
}));
