import React, { CSSProperties, useCallback, useMemo, useState } from "react"
import Measure, { ContentRect } from 'react-measure';
import { AppBar, makeStyles } from "@material-ui/core"

import { useIsDesktopBreakpoint } from "../../../utils/breakpoint";
import { useScrollPosition } from "../../../utils/useScrollPosition"

import { HeaderNav } from "./HeaderNav";
import { HeaderTitle } from "./HeaderTitle";
import { HeaderCover } from "./HeaderCover";



const minHeaderHeight = 100; // todo

const coverHeights = {
	"sm": 40,
	"md": 61.8,
	"lg": 85,
};

type CoverHeightCode = keyof typeof coverHeights;

export interface HeaderProps {
	coverSize: CoverHeightCode,
	coverImage: string,
	coverAlign?: string
	title?: React.ReactNode,
}


export const Header = (props: HeaderProps) => {

	const { title, coverSize, coverImage, coverAlign } = props;
	const classes = useStyles();

	const isDesktop: boolean = useIsDesktopBreakpoint();

	const [measuredHeaderHeight, setMeasuredHeaderHeight] = useState<number | undefined>(undefined);
	const handleHeaderResize = useCallback(
		(contentRect: ContentRect) => setMeasuredHeaderHeight(contentRect!.client!.height),
		[setMeasuredHeaderHeight],
	);

	const scrollPixels = measuredHeaderHeight !== undefined ? measuredHeaderHeight - minHeaderHeight : undefined;
	const scrollY = useScrollPosition(isDesktop && scrollPixels !== undefined ? scrollPixels : 0);

	const ready: boolean = (isDesktop && scrollPixels !== undefined);
	const collapseProgress = ready ? scrollPixels! <= 0 ? 1 : Math.min(scrollY / scrollPixels!, 1.0) : undefined;
	const collapsed = ready && (collapseProgress === 1);
	const headerElevation = ready && collapsed ? 3 : 0;
	const coverTranslate = ready ? collapseProgress! * scrollPixels! * 0.2 : 0;

	const appBarStyle = useMemo(
		(): CSSProperties | undefined => (ready && collapsed ? { position: "fixed", top: -scrollPixels!} : undefined),
		[ready, scrollPixels, collapseProgress],
	);

	return (
		<Measure client onResize={handleHeaderResize}>
			{({measureRef}) => (
				<>
					<div
						ref={measureRef}
						className={(classes as any)["height__" + coverSize]}
					/>
					<AppBar
						position="absolute"
						className={classes.root + ' ' + (classes as any)["height__" + coverSize]}
						elevation={headerElevation}
						style={appBarStyle}
					>
						<HeaderCover
							coverImage={coverImage}
							coverAlign={coverAlign}
							coverTranslate={coverTranslate}
							collapseProgress={collapseProgress}
						/>
						<HeaderNav position={ready && collapsed ? scrollPixels! : undefined}/>

						<HeaderTitle
							title={title}
							collapseProgress={collapseProgress}
						/>
					</AppBar>
				</>
			)}
		</Measure>
	);
};


const heights: {[key: string]: any} = {};
Object.keys(coverHeights).forEach(key => {
	heights['height__' + key] = {
		height: coverHeights[key as CoverHeightCode] + 'vh',
	};
});

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: "#000",
		overflow: "hidden",
		top: 0,
	},
	...heights,

}));


