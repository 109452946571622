import React from "react"
import { Box, Button } from "@material-ui/core"

import { ReservationFormBody } from "./ReservationFormBody"
import { ReservationFormWrapper } from "./ReservationFormWrapper";



export interface ReservationFormProps {
	variant: 'filled' | 'outlined',
}


export const ReservationForm = (props: ReservationFormProps) => {
	const { variant } = props;

	return (
		<div>
			<ReservationFormWrapper>
				<ReservationFormBody
					variant={variant}
				/>
				<Box marginTop={1}>
					<Button type="submit" variant="contained" color="primary">Odoslať</Button>
				</Box>
			</ReservationFormWrapper>
		</div>
	);
}

