import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
} from "@material-ui/core"
import React, { useCallback } from "react"
import { ReservationFormBody } from "./ReservationFormBody"
import { Dialog } from "../Dialogs/Dialog"
import { ReservationFormWrapper } from "./ReservationFormWrapper";


export interface ReservationFormDialogProps {
	open: boolean;
	onClose: () => void,
}


export const ReservationFormDialog = (props: ReservationFormDialogProps) => {
	const { open, onClose } = props;

	const classes = useStyles();
	const handleClose = useCallback(() => onClose(), [onClose]);

	return (
			<Dialog
				maxWidth="md"
				open={open}
				onClose={onClose}
			>
				<ReservationFormWrapper className={classes.dialogInner}>
					<DialogTitle>Rezervácia pobytu</DialogTitle>
					<DialogContent dividers>
						<ReservationFormBody variant="outlined" />
					</DialogContent>
					<DialogActions>
						<Button type="button" color="default" onClick={handleClose}>Zavrieť</Button>
						<Button type="submit" color="primary" autoFocus>Odoslať</Button>
					</DialogActions>
				</ReservationFormWrapper>
			</Dialog>
	);
}


const useStyles = makeStyles(theme => ({
	dialogInner: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',
	}
}));
