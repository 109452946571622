import React from "react"
import { Grid, List, ListItem, makeStyles, SwipeableDrawer } from "@material-ui/core"
import HiddenCss from "@material-ui/core/Hidden/HiddenCss"

import { menu } from "../../data/menu"
import { useHandleDialogOpen } from "../Dialogs"
import { ButtonLink } from "../Link"



export interface DrawerMenuProps {
	open: boolean,
	onOpen: () => void
	onClose: () => void
}

const drawerMenu: typeof menu = [
	["/", "Úvod"],
	...menu,
];

export const DrawerMenu = (props: DrawerMenuProps) => {

	const { open, onOpen, onClose } = props;
	const classes = useStyles();
	const handleReservationClick = useHandleDialogOpen("reservation", onClose);

	return (
		<SwipeableDrawer
			anchor="bottom"
			disableSwipeToOpen
			open={open}
			onOpen={onOpen}
			onClose={onClose}
		>
			<div className={classes.inner}>

				<Grid container>
					<Grid item xs={12} sm={6}>
						<HiddenCss xsDown>
							{/*todo: logo<br />*/}
							{/*todo: kontakty<br />*/}
							{/*todo: socials*/}
						</HiddenCss>
					</Grid>
					<Grid item xs={12} sm={6}>

						<List>
							{drawerMenu.map((item, index) => (
								<ListItem
									key={index}
									className={classes.listItem}
								>
									<ButtonLink
										className={classes.button}
										activeClassName={classes.buttonActive}
										classes={{label: classes.buttonLabel}}
										fullWidth
										to={item[0]}
									>
										{item[1]}
									</ButtonLink>
								</ListItem>
							))}
						</List>
						<div className={classes.grow} />
						<List>
							<ListItem>
								<ButtonLink
									variant="contained"
									color="secondary"
									fullWidth
									to="/rezervacia/"
									onClick={handleReservationClick}
								>
									Rezervovať pobyt
								</ButtonLink>
							</ListItem>
						</List>

					</Grid>
				</Grid>

			</div>
		</SwipeableDrawer>
	);
}


const useStyles = makeStyles(theme => ({
	inner: {
		// display: "flex",
		// flexDirection: "column",
		// height: '100%'
	},
	grow: {
		// flexGrow: 1,
	},
	listItem: {
		padding: theme.spacing(0.5, 1),
	},
	button: {
		textTransform: "none",
		padding: theme.spacing(0.5, 2),
	},
	buttonLabel: {
		justifyContent: 'start',
	},
	buttonActive: {
		//backgroundColor: theme.palette.action.selected,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	}
}));
