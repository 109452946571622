import React, { CSSProperties, useMemo } from "react";
import clsx from "clsx";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import HiddenCss from "@material-ui/core/Hidden/HiddenCss";

import { menu } from "../../../data/menu";
import { useIsDesktopBreakpoint } from "../../../utils/breakpoint";

import { useHandleDialogOpen } from "../../Dialogs";
import { ButtonLink, Link } from "../../Link";

import logo from "../../../images/logo/logo-studnicky-white.svg";



export interface HeaderNavProps {
	position: number | undefined;
}


export const HeaderNav = React.memo((props: HeaderNavProps) => {
	const classes = useStyles();
	const isDesktop = useIsDesktopBreakpoint();
	const handleRezervaciaClick = useHandleDialogOpen("reservation");

	const { position } = props;
	const navStyle: CSSProperties | undefined = useMemo(() => position !== undefined ? {
		position: 'absolute',
		top: position,
	} : undefined, [position]);

	return (
		<nav className={clsx(classes.mainMenu, isDesktop && classes.mainMenu__fixed)} style={navStyle}>
			<Container maxWidth="lg" className={classes.mainMenu__container}>
				<Typography variant="inherit" component="h1">
					<Link to="/">
						<img
							src={logo}
							alt="Hotel Studničky***"
							className={classes.mainMenu__logo}
						/>
					</Link>
				</Typography>
				<HiddenCss smDown>
					<ul className={classes.mainMenu__list}>
						{menu.map((item, index) => (
							<li key={index}>
								<ButtonLink
									color="inherit"
									to={item[0]}
									className={classes.mainMenu__link}
									activeClassName={classes.mainMenu__activeLink}
								>
									{item[1]}
								</ButtonLink>
							</li>
						))}
						<Box component="li" marginLeft={1}>
							<ButtonLink to="/rezervacia" variant="contained" color="secondary" onClick={handleRezervaciaClick}>
								Rezervácia
							</ButtonLink>
						</Box>
					</ul>
				</HiddenCss>
			</Container>
		</nav>
	);
});


const logoSize = 0.65;

const useStyles = makeStyles(theme => ({

	mainMenu: {
		backgroundImage: 'linear-gradient(rgba(0,0,0, 0.3), rgba(0,0,0, 0.0))',
		paddingBottom: '5vh',
	},

	mainMenu__fixed: {
		position: "fixed",
		display: "block",
		top: 0, left: 0, right: 0,
		zIndex: 2,
	},
	mainMenu__container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: 100,
		position: "relative",
		zIndex: 1,
	},
	mainMenu__logo: {
		width: 300 * logoSize,
		height: 81 * logoSize,
	},

	mainMenu__list: {
		listStyle: "none",
		display: "flex",
		"& li a": {
			padding: theme.spacing(1, 2),
		}
	},

	mainMenu__link: {
		marginRight: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			fontSize: '92%',
		},
		'&:hover': {
			background: 'rgba(255, 255, 255, 0.2)',
		}
	},

	mainMenu__activeLink: {
		// fontWeight: 'bold',
		// color: 'rgba(0,0,0, 0.7)',
		// borderBottom: 'solid 2px',
		// borderBottomRightRadius: 0,
		// borderBottomLeftRadius: 0,
		//background: 'rgba(255, 255, 255, 0.5)',
		color: 'rgba(0,0,0, 0.9)',
		background: 'rgba(255, 255, 255, 0.9)',

		cursor: 'default',
		'&:hover': {
			background: 'rgba(255, 255, 255, 0.9)',
		}
	},

}));
