import React from "react"
import { Fab, makeStyles } from "@material-ui/core"
import { Menu as MenuIcon } from "@material-ui/icons"
import { FabProps } from "@material-ui/core/Fab"



export interface FloatingMenuButtonProps {
	onClick?: FabProps["onClick"],
}


export const FloatingMenuButton = (props: FloatingMenuButtonProps) => {
	const { onClick } = props;
	const classes = useStyles();

	return (
		<Fab
			variant="extended"
			color="secondary"
			aria-label="menu"
			onClick={onClick}

			className={classes.fab}
		>
			<MenuIcon className={classes.fabIcon} />
			Menu
		</Fab>
	);
}


const useStyles = makeStyles(theme => ({
	fab: {
		position: "fixed",
		bottom: theme.spacing(5),
		right: 0,
		paddingLeft: theme.spacing(2.5),
		paddingRight: theme.spacing(4),
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		zIndex: theme.zIndex.appBar,
	},
	fabIcon: {
		marginRight: theme.spacing(2),
	}
}));
