import React from "react"
import { makeStyles, Omit, Slide, useMediaQuery, useTheme } from "@material-ui/core"
import { default as MuiDialog, DialogProps as MuiDialogProps } from "@material-ui/core/Dialog/Dialog"
import { TransitionProps } from "@material-ui/core/transitions"



export type DialogProps = Omit<MuiDialogProps, "fullScreen" | "fullWidth" | "style">;


export const Dialog = (props: DialogProps) => {

	const { children, ...rest } = props;
	const classes = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<MuiDialog
			TransitionComponent={Transition}
			{...rest}
			fullWidth={true}
			fullScreen={fullScreen}
			style={fullScreen ? {
				left: theme.spacing(1),
				top: theme.spacing(1),
				right: theme.spacing(1),
				bottom: theme.spacing(1),
				borderRadius: theme.spacing(1),
			} : undefined}
			classes={{
				paperFullScreen: classes.paperFullScreen,
			}}
		>
			{children}
		</MuiDialog>
	);
};



const useStyles = makeStyles(theme => ({
	paperFullScreen: {
		borderRadius: theme.shape.borderRadius,
	}
}));


const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
	return (
		<Slide direction="up" ref={ref} {...props} />
	);
});
