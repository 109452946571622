import React from "react"
import { useHandleDialogOpen } from "../Dialogs"
import { Anchor } from "../Link";


export interface GdprLinkProps {
	children: React.ReactNode,
}


export const GdprAnchor = (props: GdprLinkProps) => {
	const { children } = props;

	const handleClick = useHandleDialogOpen("gdpr");

	return (
		<Anchor to="/ochrana-osobnych-udajov/" onClick={handleClick}>{children}</Anchor>
	);
}
