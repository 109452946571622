import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"



export interface SeoProps {
	lang: string,
	title: string,
	description?: string,
	robots?: "noindex",
}


export const Seo = (props: SeoProps) => {

	const { lang, title: pageTitle, robots: pageRobots, description: pageDescription = '' } = props;
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	const siteTitle = site.siteMetadata.title;
	const title = pageTitle ? `${pageTitle} | ${siteTitle}` : siteTitle;
	const description = pageDescription || site.siteMetadata.description;
	const robots = pageRobots ? pageRobots : 'index, follow';

	return (
		<Helmet
			htmlAttributes={{lang}}
			title={title}
			meta={[
				{ name: `description`, content: description },
				{ property: `og:title`, content: title },
				{ property: `og:description`, content: description },
				{ property: `og:type`, content: `website` },
				{ name: `robots`, content: robots },
			]}
		/>
	)
};
