import React from "react";



export interface ReservationFormWrapperProps {
	children: React.ReactNode,
	className?: string;
}


export const ReservationFormWrapper = (props: ReservationFormWrapperProps) => {
	const { children, className } = props;

	return (
		<form method="post" action="/api/reservation" className={className}>
			{children}
		</form>
	);

};
