import React from "react"

import { Button, Checkbox, FormControlLabel, makeStyles, TextField, Typography } from "@material-ui/core"
import { GdprAnchor } from "../Gdpr"


export interface NewsletterSignUpFormProps {

}


export const NewsletterSignUpForm = (props: NewsletterSignUpFormProps) => {
	const classes = useStyles();
	return (
		<form
			action="https://studnicky.us16.list-manage.com/subscribe/post?u=e15bd7c18f71d3f1157c60ed9&amp;id=a34d03efee"
			method="post"
		>
			<TextField
				name="EMAIL"
				type="email"
				placeholder="e-mail"
				defaultValue=""
				variant="outlined"
				margin="dense"
				fullWidth
				InputProps={{
					endAdornment: (
						<Button type="submit" name="subscribe" variant="text" className={classes.submit}>Prihlásiť</Button>
					),
					classes: {
						adornedEnd: classes.inputAdornedEnd
					}
				}}
			/>
			<FormControlLabel
				value=""
				control={<Checkbox color="primary" required />}
				label={(
					<Typography variant="body2"><small>
						{'Súhlasím so spracovaním osobných údajov na kontaktné účely '}
						(<GdprAnchor>viac informácií</GdprAnchor>).
					</small></Typography>
				)}
				labelPlacement="end"
			/>
			<div className={classes.honeypot} aria-hidden={true}>
				<input type="text" name="b_e15bd7c18f71d3f1157c60ed9_a34d03efee" tabIndex={-1} defaultValue="" />
			</div>
		</form>
	);
}


const useStyles = makeStyles(theme => ({
	inputAdornedEnd: {
		paddingRight: 2,
	},
	honeypot: {
		position: "absolute",
		left: -5000,
	},
	submit: {
		minWidth: 'auto',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	}
}));
